@import "../../../scss/imports";

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 1px #fff) drop-shadow(0 0 2px #fff)
      drop-shadow(0 0 3px rgba(32, 215, 228, 0.81))
      drop-shadow(0 0 4px rgba(32, 215, 228, 0.81));
  }
  50% {
    filter: drop-shadow(0 0 1px #fff) drop-shadow(0 0 3px #fff)
      drop-shadow(0 0 6px rgba(32, 215, 228, 0.81))
      drop-shadow(0 0 8px rgba(32, 215, 228, 0.81));
  }
  100% {
    filter: drop-shadow(0 0 1px #fff) drop-shadow(0 0 2px #fff)
      drop-shadow(0 0 3px rgba(32, 215, 228, 0.81))
      drop-shadow(0 0 4px rgba(32, 215, 228, 0.81));
  }
}

.sponsor_container {
  padding: 100px 0px 120px;
  @media screen and (max-width: $mobile-view) {
    padding: 85px 0px 50px;
  }
  overflow: hidden auto;
}
.sponsor_text_container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.hr-container {
  padding: 8px 40px;
  display: inline-block;
  @media screen and (max-width: $mobile-view) {
    padding: 2px 20px;
  }
}
.hr-txt {
  padding: 10px 0px 0px;
  width: 80px;
  &__line1 {
    padding: 10px 0px 0px;
    margin-left: 128px;
    @media screen and (max-width: $mobile-view) {
      width: 35px;
      padding-top: 5px;
      margin-left: 45px;
    }
  }
  @media screen and (max-width: $mobile-view) {
    width: 35px;
    padding-top: 5px;
  }
}
.hr-txt2 {
  width: 208px;
  padding-top: 10px;
  @media screen and (max-width: $mobile-view) {
    width: 80px;
    padding-top: 5px;
  }
}
.sponsors_text {
  font-family: Galano-Bold;
  width: max-content;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.22;
  text-align: left;
  color: #ffffff;
  @media screen and (max-width: $mobile-view) {
    font-size: 20px;
  }
}
.sponsor_wrapper {
  padding-top: 45px;
  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: auto auto auto auto;
  @media screen and (max-width: $mobile-view) {
    padding: 30px 5px;
    grid-template-columns: auto auto;
  }
}
.sponsor_img {
  height: 60px;
  align-self: center;
  justify-self: center;
  animation: glow 4s linear infinite;
  @media screen and (max-width: $QHD-screen){
    height: 45px;
  }
  @media screen and (max-width: $mobile-view) {
    height: 30px;
  }
  &__img2{
    height: 90px;
    align-self: center;
    justify-self: center;
    animation: glow 4s linear infinite;
    @media screen and (max-width: $QHD-screen){
      height: 70px;
    }
    @media screen and (max-width: $mobile-view) {
      height: 50px;
    }
    @media screen and (max-width: $small-mobile){
      height: 40px;
    }
  }
}

.sponsor_none{
  @media screen and (max-width: $mobile-view){
    display: none;
  }
}