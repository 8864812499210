@import "../../../scss/imports";

.gallery {
  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__images {
    height: 800px;
    padding: 10px;
    border: 2px solid $color-style;
    border-radius: 10px;
    box-sizing: border-box;

    &__container {
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(3, auto);
      grid-auto-flow: dense;
      gap: 10px;
      height: 100%;
      width: 100%;

      div {
        display: block;
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        filter: grayscale(1) brightness(50%);
        transition: filter 0.15s, background-size 0.2s ease-out;

        &:hover {
          filter: grayscale(0) brightness(100%);
          background-size: 115% 115%;

          &.wide {
            background-size: 120% 120%;
          }
          &.tall {
            background-size: 110% 110%;
          }
          &.big {
            background-size: 110% 110%;
          }
        }

        &.wide {
          grid-area: auto / span 2;
          background-size: 100% 100%;
          background-position: top;
          max-height: 600px;
        }

        &.big {
          grid-area: span 2 / span 2;
          background-size: 100% 100%;
          max-height: 600px;
        }

        &.tall {
          grid-area: span 2;
          background-size: 100% 100%;
          max-height: 600px;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-view) {
    &__images {
      border-left: 0px;
      border-right: 0px;
      border-radius: 0px;
      height: 200px;
      overflow: auto hidden;
      width: 100%;
      padding: 20px 10px;

      &__container {
        display: block;
        width: max-content;
        height: 100%;
        box-sizing: border-box;
        background-repeat: no-repeat;
        div {
          display: inline-block;
          height: 100%;
          width: 277px;
          background-size: 100% 100%;
          background-position: center;
          filter: grayscale(0);

          &:hover {
            background-size: 115% 115%;
          }

          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
