$color-background: #202020;
$color-style: #29abe2;
$color-body: #fff;
$color-planet: #f052e0;
$color-moon: #52f09c;

$mobile-view: 1000px;
$QHD-screen:1440px;
$hd-screen: 1366px;
$small-mobile: 400px;

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0);
    scrollbar-face-color: $color-style;
    scrollbar-track-color: $color-background;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-style;
    transition: 0.15s;
  
    &:hover {
      cursor: pointer;
      background-color: lighten($color-style, 10%);
    }
  
    &:active {
      background-color: darken($color-style, 10%);
    }
  }
  
  &::-webkit-scrollbar-track {
    background-color: $color-background;
  } 
}