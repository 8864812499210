@import "../../../scss/imports";
@import "../../../scss/base";

.aboutcontainer {
  display: flex;
  flex-direction: row;
  margin: 7% 0;
  overflow: hidden;
  @media screen and (max-width: $mobile-view) {
    flex-flow: column wrap;
    margin-bottom: 13%;
  }

  &__images {
    margin: 3%;
    @media screen and (max-width: $mobile-view) {
      height: 70%;
    }
    padding-right: 5%;

    &__spaceship1 {
      width: 30vw;
      z-index: 1;
      display: flex;
      position: relative;
      top: -2.5vw;

      @media screen and (max-width: $mobile-view) {
        width: 75%;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__spaceship2 {
      width: 30vw;
      display: flex;
      z-index: 2;
      position: relative;
      top: -8vw;
      animation: space-float 2s ease-in-out infinite alternate;

      @media screen and (max-width: $mobile-view) {
        width: 70%;
        top: -11vw;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__spaceship3 {
      width: 16vw;
      position: relative;
      display: flex;
      z-index: 1;
      top: -15vw;
      left: 10.5vw;

      @media screen and (max-width: $mobile-view) {
        width: 40%;
        top: -25vw;
        left: 9vw;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__Recpurplebig {
      width: 3vw;
      height: 3vw;
      position: relative;
      left: 5.5vw;
      z-index: 3;

      @media screen and (max-width: $mobile-view) {
        width: 8vw;
        height: 8vw;
        top: 8vw;
        left: 24vw;
      }
    }

    &__Recpurplesmall {
      width: 1.2vw;
      height: 1.2vw;
      position: relative;
      top: 0.5vw;
      left: 6vw;
      z-index: 3;

      @media screen and (max-width: $mobile-view) {
        width: 3vw;
        height: 3vw;
        top: 9vw;
        left: 25vw;
      }
    }

    &__Recpink {
      width: 2.5vw;
      height: 2.5vw;
      position: relative;
      top: 31vw;
      left: 21.5vw;
      z-index: 3;

      @media screen and (max-width: $mobile-view) {
        width: 6vw;
        height: 6vw;
        top: 73vw;
        left: 57vw;
      }
    }

    &__Circpin {
      width: 2.5vw;
      height: 2.5vw;
      position: relative;
      top: 34vw;
      left: 4vw;
      z-index: 3;

      @media screen and (max-width: $mobile-view) {
        width: 6vw;
        height: 6vw;
        top: 81vw;
        left: 20vw;
      }
    }

    &__Circpurple {
      width: 3vw;
      height: 3vw;
      position: relative;
      top: 5vw;
      left: 19vw;
      z-index: 3;

      @media screen and (max-width: $mobile-view) {
        width: 7vw;
        height: 7vw;
        top: 19vw;
        left: 50vw;
      }
    }
  }

  &__card {
    margin: 3%;
    display: flex;
    flex-flow: column;

    @media screen and (max-width: $mobile-view) {
      margin: 0%;
    }

    &__header {
      margin: 0 auto 30px;
      width: 100%;
      text-align: center;
      justify-content: center;

      margin-bottom: 40px;

      @media screen and (max-width: $mobile-view) {
        align-items: center;
        margin: 0 auto;
      }
    }

    &__content {
      font-size: 25px;
      font-family: "Gilroy-Light";
      line-height: 30px;
      text-align: start;
      border-left: 2px solid $color-style;
      padding-left: 30px;
      @media screen and (max-width: $mobile-view) {
        text-align: center;
        font-size: 16px;
        margin: auto;
        width: 80%;
        line-height: 18px;
        padding-left: 0px;
        border-left: 0px;
      }

      &__para2 {
        margin-top: 20px;
      }
    }
  }

  @media screen and(max-width:$mobile-view) {
    flex-flow: column;
    margin-left: 0px;
  }
}
