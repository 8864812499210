@import "../../scss/imports";

.team {
  height: 100vh;
  overflow: hidden;

  &__container {
    padding-top: 60px;
    display: grid;
    grid-template-columns: 225px auto;
    height: 100%;
    box-sizing: border-box;

    &__names {
      &__name {
        font-family: "Gilroy-Light", Arial, Helvetica, sans-serif;
        font-size: 22px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        opacity: 0.7;
        transition: 0.15s;

        &:not(:last-of-type) {
          margin-bottom: 25px;
        }

        &.active {
          opacity: 1;
          color: $color-style;
          padding-left: 10px;
        }

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    &__members {
      height: calc(100vh - 145px);
      padding-right: 30px;
      overflow: visible auto;

      // Custom Scrollbar styling
      &::-webkit-scrollbar {
        width: 10px;
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba($color-style, 0.8);
      }

      &::-webkit-scrollbar-track {
        // border: 1px solid $color-style;
        background-color: $color-background;
      }

      // For Internet Explorer
      & {
        scrollbar-face-color: $color-style;
        scrollbar-track-color: $color-background;
      }

      &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        justify-content: space-around;
        margin-bottom: 200px;
        padding-top: 30px;
      }

      &__shadow-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        height: 200px;
        width: 100%;
        position: absolute;
        background: linear-gradient(to bottom, transparent, $color-background);
        pointer-events: none;
      }
    }
  }

  .team-member {
    display: grid;
    grid-template-columns: 260px 40px;
    margin: 0 0 30px auto;

    &__left {
      margin-right: 30px;
      width: 230px;
      text-align: center;

      &__name {
        font-family: "Galano-Bold", Arial;
        font-weight: 800;
        font-size: 24px;
        margin-top: 30px;
      }

      &__role {
        font-family: "Gilroy-Light", Arial;
        font-weight: 100;
        font-size: 20px;
        margin-top: 10px;
      }

      &__image {
        height: 230px;
        width: 230px;
        position: relative;

        &__profile {
          height: 180px;
          width: 180px;
          border-radius: 50%;
          border: 3px solid $color-style;
          background-position: center;
          background-size: cover;
          position: absolute;
          top: calc(50% - 90px);
          left: calc(50% - 90px);
        }

        &__planet {
          width: 110%;
          height: 24px;
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 55%);
          animation: particles-rotate 8s linear infinite;

          span {
            transition: 0.3s;
            display: block;
            height: 24px;
            width: 24px;
            background-color: $color-planet;
            border-radius: 50%;
          }
        }

        &__moon {
          width: 125%;
          height: 16px;
          position: absolute;
          top: calc(50% - 8px);
          left: calc(50% - 62.5%);
          animation: particles-rotate 6s linear infinite reverse;

          span {
            transition: 0.3s;
            display: block;
            height: 16px;
            width: 16px;
            background-color: $color-moon;
            border-radius: 50%;
          }
        }
      }
    }

    &__social {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 26px;
        transition: 0.15s;
        margin-top: 25px;

        i {
          color: $color-style;
          transition: 0.15s;
        }

        &:hover {
          cursor: pointer;

          i {
            color: $color-body;
            transform: scale(1.25);
          }
        }
      }
    }

    &:hover {
      .team-member__left {
        &__image {
          &__planet,
          &__moon {
            span {
              background-color: $color-body;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-view) {
    &__container {
      &__names {
        position: fixed;
        height: 75px;
        bottom: 0;
        width: 100vw;
        overflow: auto hidden;
        box-sizing: border-box;

        &__container {
          width: max-content;
          min-width: 100vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 10px;
        }

        &__name {
          display: inline-block;
          margin-bottom: 0px !important;
          padding: 10px 10px 0px;

          &.active {
            padding-top: 0px;
          }
        }
      }

      &__members {
        height: calc(100vh - 145px - 50px);
        position: fixed;
        bottom: 75px;
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100vw;
        overflow-x: hidden;

        &__container {
          grid-template-columns: 1fr;
        }

        &__shadow-bottom {
          position: fixed;
          bottom: 74px;
        }
      }
    }

    .team-member {
      grid-template-columns: 1fr;

      &__left {
        margin: 0;
      }

      &__social {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}
