@import "../../../scss/imports";

.cover {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__titles {
    color: $color-body;

    &__title {
      font-family: "Galano-Bold", "Arial";
      font-size: 89px;
      text-transform: uppercase;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
    }

    &__date {
      font-family: "Gilroy-Light", "Arial";
      font-size: 37px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: normal;
      text-align: left;
      margin-top: 14px;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: block;
        margin-left: 25px;
        background-color: $color-style;
        height: 2px;
        width: 75px;
        border-radius: 5px;
      }
    }

    &__timer {
      display: flex;
      align-items: center;
      margin-top: 100px;
      font-family: "Gilroy-Light", "Arial";
      font-size: 22px;
    }

    @media screen and (max-width: $hd-screen) {
      &__title {
        font-size: 64px;
      }

      &__date {
        font-size: 28px;

        &::after {
          width: 50px;
        }
      }

      &__timer {
        font-size: 18px;
        margin-top: 75px;
      }
    }
  }

  &__image {
    width: 400px;
    height: 400px;
    position: relative;

    img {
      position: absolute;
    }

    &__helmet {
      width: 100%;
    }

    &__bg-left {
      width: 88%;
      left: -20px;
      top: -30px;
    }

    &__bg-right {
      width: 20%;
      right: -20px;
    }

    @media screen and (max-width: $hd-screen) {
      height: 300px;
      width: 300px;
    }
  }

  &__social-links {
    position: fixed;
    right: 20px;
    bottom: 40px;

    &__container {
      display: flex;
      flex-flow: column;
      align-items: center;

      &::before {
        content: "";
        height: 75px;
        width: 3px;
        background-color: $color-style;
        border-radius: 2px;
      }
    }

    &__link {
      text-align: center;
      transition: 0.1s linear;
      margin-top: 20px;

      &.down-arrow {
        display: none;
      }

      i {
        font-size: 28px;
        width: 28px;
        color: $color-style;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.2) rotate(-27deg);
      }
    }

    @media screen and (max-width: $mobile-view) {
      position: relative;
      bottom: 0;
      right: 0;
      text-align: center;
      margin-top: 50px;

      &__container {
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        &::before {
          display: none;
        }
      }

      &__link {
        margin-top: 0;

        &:not(:last-of-type) {
          margin-right: 30px;
        }

        &.down-arrow {
          display: initial;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-view) {
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-top: 60px;
    max-width: 100%;

    &__titles {
      &__title {
        text-align: center;
        font-size: 48px;
      }

      &__date {
        display: none;
      }
    }

    &__image {
      width: 70%;
      max-width: 300px;
      height: 300px;
    }
  }
}

.Recpurple1 {
  width: 50px;
  height: 50px;
  position: relative;
  top: -52px;
  left: 185px;
  z-index: 3;

  @media screen and (max-width: $mobile-view) {
    width: 44px;
    height: 44px;
    top: -51px;
    left: 130px;
  }
}

.Circpin1 {
  width: 20px;
  height: 20px;
  position: relative;
  top: 10px;
  left: 365px;
  z-index: 3;

  @media screen and (max-width: $hd-screen) {
    left: 286px;
  }

  @media screen and (max-width: $mobile-view) {
    width: 20px;
    height: 20px;
    top: 20px;
    left: 280px;
  }

  @media screen and (max-width:400px) {
    top: 15px;
    left: 255px;
  }
}

.Recpurple2 {
  width: 30px;
  height: 30px;
  position: relative;
  top: 245px;
  left: 30px;
  z-index: 3;

  @media screen and (max-width: $hd-screen) {
    top: 180px;
    left: 10px;
  }

  @media screen and (max-width: $mobile-view) {
    width: 25px;
    height: 25px;
    top: 165px;
    left: 15px;
  }

  @media screen and (max-width:400px) {
    top: 150px;
    left: 10px;
  }
}

.Circpurple1 {
  width: 40px;
  height: 40px;
  position: relative;
  top: 80px;
  left: -30px;
  z-index: 3;

  @media screen and (max-width: $hd-screen) {
    top: 50px;
  }

  @media screen and (max-width: $mobile-view) {
    width: 30px;
    height: 30px;
    top: 50px;
    left: -25px;
  }
}

.Circpurple2 {
  width: 10px;
  height: 10px;
  position: relative;
  top: 130px;
  left: 3px;
  z-index: 3;
  border: solid;
  border-radius: 10px;
  border-width: 1.5px;

  @media screen and (max-width: $hd-screen) {
    top: 100px;
    left: 0px;
  }

  @media screen and (max-width: $mobile-view) {
    width: 8px;
    height: 8px;
    top: 90px;
    left: 0px;
  }
}

.Circpurple3 {
  width: 5px;
  height: 5px;
  position: relative;
  top: -50px;
  left: 230px;
  z-index: 3;
  border: solid;
  border-radius: 10px;
  border-width: 1px;

  @media screen and (max-width: $mobile-view) {
    top: -50px;
    left: 170px;
  }
}

.Circpin2 {
  width: 10px;
  height: 10px;
  position: relative;
  top: 150px;
  left: 400px;
  border: solid;
  border-radius: 10px;
  border-width: 1px;


  @media screen and (max-width: $hd-screen) {
    top: 120px;
    left: 300px;
  }

  @media screen and (max-width: $mobile-view) {
    width: 8px;
    height: 8px;
    top: 100px;
    left: 300px;
  }

  @media screen and (max-width:400px) {
    top: 90px;
    left: 270px;
  }
}