@import "../../scss/imports";

.footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    border-top: 2px solid grey;
    width: 100%;
    height: 180px;
    margin: 0 auto;
    padding-top: 56px;
    padding-bottom: 32px;
    overflow: hidden;

    @media (min-width: 600px) and (max-width: 823px) {
        padding-top: 21px;
        padding-bottom: 0px;
        height: 80%;
    }

    @media (max-width: 600px) {
        justify-content: center;
        width: 96%;
        flex-flow: column;
        padding: 21px 0px 50px;
        height: 450px;
        margin: 2%;
    }
}

.footer_u {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;
    height: 150%;
    margin: 0 auto;

    @media (min-width: 600px) and (max-width: 823px) {
        width: 70%;
        height: 80%;
    }

    &__logo {
        display: flex;
        flex-flow: row;
        justify-content: left;

        @media (max-width: 823px) {
            justify-content: center;
        }

        @media (max-width: 600px) {
            justify-content: center;
            width: 100%;
            height: 95%;
        }

        &__div {
            @media (max-width: 600px) {
                justify-content: center;
                width: 33%;
                height: 100%;
                margin: 0%;
            }
        }
    }

    &__logo_svg {
        padding-top: 36px;
        padding-left: 8%;
        width: 120px;

        @media (min-width: 824px) and (max-width: 1280px) {
            width: 90px
        }

        @media (min-width: 600px) and (max-width: 823px) {
            width: 60px;

        }

        @media (max-width: 600px) {
            width: 64%;
            height: 60%;
            justify-content: right;
            padding: 20% 0% 12% 30%;
        }
    }

    &__logo_span {
        padding: 72px 24px 0px 12px;
        align-content: flex-end;
        width: 300px;
        height: 168px;
        font-size: 21.6px;
        font-weight: 280;
        line-height: 1.13;
        text-align: left;
        color: #ffffff;

        @media (min-width: 824px) and (max-width: 1280px) {
            padding: 64px 24px 0px 10px;
            align-content: flex-start;
            width: 240px;
            height: 144px;
            font-size: 15px;
        }

        @media (min-width: 600px) and (max-width: 823px) {
            padding: 54px 24px 0px 10px;
            align-content: flex-start;
            width: 170px;
            height: 126px;
            font-size: 12px;
        }

        @media (max-width: 600px) {
            padding: 10% 0 4% 0;
            align-content: flex-start;
            width: 75%;
            height: 51.7%;
            font-size: 16px;
        }
    }
}

.footer_l {
    display: flex;
    flex-flow: row;
    justify-content: center;

    @media (min-width: 812px) {
        justify-content: flex-end;
        width: 78%;
    }

    @media (max-width: 823px) {
        justify-content: flex-start;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        width: 89%;
        height: 80%
    }

    @media (max-width: 600px) {
        flex-flow: column;
        margin: auto;
        justify-content: center;
        width: 100%;
        height: 100%
    }

    &__links {
        width: 160px;
        height: 168px;
        font-size: 100%;
        font-weight: 300;
        line-height: 1.15;
        text-align: left;
        color: #ffffff;
        padding-left: 5%;

        @media (max-width: 1280px) {
            font-size: 12px;
            padding-left: 0%;
            width: 144px;
        }

        @media (max-width: 823px) {
            width: 90px;
            height: 122px;
            font-size: 10px;
            padding-left: 0px;
        }

        @media (max-width: 600px) {
            width: 100%;
            font-size: 87.04%;
            padding-left: 0px;
            height: 90%;
            justify-content: center;
            text-align: center;
            padding-bottom: 2rem;
        }

        &_content {
            padding: 8px 2px;
            width: 400px;

            @media (max-width: 823px) {
                width: 100%;
                padding: 6px 2px;
            }

            @media (max-width: 600px) {
                width: 100%;
                padding: 6px 0px;
            }
        }

        &_cn {
            color: #ffffff;
        }
    }

    &__contact {
        width: 100%;
        height: 168px;
        font-size: 100%;
        font-weight: 300;
        line-height: 1.15;
        text-align: left;
        color: #ffffff;
        padding-left: 3.2%;

        @media (max-width: 1280px) {
            font-size: 12px;
            padding-left: 0%;
        }

        @media (max-width: 823px) {
            font-size: 10px;
            padding-left: 3%;
        }

        @media (max-width: 600px) {
            width: 100%;
            font-size: 87.04%;
            padding-left: 0px;
            text-align: center;
            justify-content: center;
            padding-bottom: 10px;
        }

        &_content {
            padding: 8px 2px;
            width: 400px;

            @media (max-width: 823px) {
                width: 100%;
                padding: 6px 2px;
            }

            @media (max-width: 600px) {
                width: 100%;
                padding: 6px 0px;
            }
        }

        &_cn {
            color: #ffffff;
        }

        &_gmap {
            padding: 16px 0px;

            @media (max-width: 823px) {
                padding: 12px 0px;
            }
        }
    }

    &__hr {
        display: flex;
        font-family: 'Galano-Bold';

        @media (max-width: 600px) {
            justify-content: center;
        }

        &_container {
            @media (max-width: 600px) {
                justify-content: center;
            }
        }
    }

    &__hr_text {
        width: 52.2px;
        padding-top: 12px;
        padding-left: 9.6px;
        height: 0px;

        @media (max-width: 823px) {
            width: 28px;
            padding-top: 8px;
        }

        @media (max-width: 600px) {
            justify-content: center;
        }

        &_1 {
            width: 28px;
            padding-top: 8px;
            padding-left: 9.6px;
            padding-right: 9.6px;
            height: 0px;

            @media (min-width: 600px) {
                display: none;
            }
        }
    }
}

a {
    color: #FFFFFF;
    text-decoration: none;
}

b {
    font-weight: 3500;
}

.footer-bottom {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, transparent, lighten($color-background, 5%), transparent);

    span {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-of-type {
            border-right: 2px solid $color-style;
        }

        img {
            margin-left: 10px;
            height: 20px;
            display: inline-block;
        }
    }

    @media screen and (max-width: $mobile-view) {
        font-size: 12px;
    }
}