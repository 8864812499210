@import "../../../scss/imports";
@import "../../../scss/base";

.main {
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  &__container {
    height: max-content;
    justify-content: center;
    align-items: center;
    display: grid;

    &__title {
      width: max-content;
      margin: 0 auto;
      font-size: 60px;
      font-weight: 800;
      font-stretch: normal;
      font-family: "Gilroy-Light";
      line-height: 1.22;
      letter-spacing: normal;
      color: #ffffff;

      @media screen and (max-width: $mobile-view) {
        font-size: 40px;
        line-height: 1;
      }
    }

    &__content {
      display: flex;
      @include custom-scrollbar;

      // Width Media Query
      @media screen and (max-width: $mobile-view) {
        flex-flow: column;
        width: 100%;
        margin: 10px auto;
        overflow: visible auto;
      }

      // Height Media Query
      @media screen and (max-height: 830px) and (max-width: $mobile-view) {
        height: calc(100vh - 400px);
        overflow-y: scroll;
      }

      &__vl {
        margin: 20px auto 0px;
        border-left: 2px solid #1bbcf1;
        font-size: 2.5rem;

        @media screen and (max-width: $mobile-view) {
          display: none;
        }
      }

      &__left {
        width: 50%;
        display: flex;
        flex-flow: column;
        margin: 20px auto 0px;
        padding-right: 10px;
        box-sizing: border-box;

        // Width Media Query
        @media screen and (max-width: $mobile-view) {
          width: 100%;
        }

        @media screen and (max-height: 600px) {
          margin: auto;
        }

        &__details {
          display: flex;
          padding: 4px;

          @media screen and (max-height: 600px) {
            padding: 4px;
          }
        }

        &__description {
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-family: "Gilroy-Light";
          line-height: 1.14;
          letter-spacing: normal;
          width: 90%;
          margin-bottom: 20px;

          @media screen and (max-width: $mobile-view) {
            padding-bottom: 10px;
            font-size: 20px;
            height: max-content;
          }

          @media screen and (max-height: 600px) {
            font-size: 12px;
          }
        }

        &__info {
          font-family: "Gilroy-Light";
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          margin-top: 10px;

          @media screen and (max-width: $mobile-view) {
            width: 100%;
            margin: 0px auto;
            padding: 0px;
            font-size: 17px;
          }

          @media screen and (max-height: 600px) {
            line-height: 1;
          }

          &__colorchange {
            color: #03b0ef;
          }

          &__nochange {
            margin-left: 4px;
            margin-top: 1px;
          }
        }
      }

      &__right {
        width: 50%;
        box-sizing: border-box;
        margin: 20px auto;
        padding: 20px;
        padding-top: 0;

        &__main {
          display: flex;
          align-items: center;
          padding: 4px;
        }

        @media screen and (max-width: $mobile-view) {
          width: 100%;
          margin: 0 auto;
          padding: 0px;
          font-size: 16px;
        }

        &__colorchange {
          color: #03b0ef;
          text-transform: uppercase;
          // margin-top: 5px;
        }

        &__reg {
          text-align: center;
          margin: 0 auto;
          padding-top: 30px;
          cursor: pointer;
          font-size: 18px;
          a {
            color: $color-style;
            transition: 0.15s;
            padding: 8px 14px;
            border: 1px solid $color-style;
            font-weight: 800;
            border-radius: 7px;

            @media screen and (max-width: $mobile-view) {
              position: fixed;
              width: max-content;
              bottom: 80px;
              left: 50%;
              right: 50%;
              transform: translateX(-50%);
            }

            &:hover {
              color: white;
              background-color: $color-style;
            }
          }
          &__but {
            display: flex;
            justify-content: center;
            padding: 10px 0;
          }
        }
      }
    }

    &__button {
      font-family: "Gilroy-Light", Arial, Helvetica, sans-serif;
      letter-spacing: 2px;
      font-size: 20px;
      border-radius: 33px;
      text-align: center;
      display: inline-block;
      padding: 14px 50px;
      font-weight: 1000;
      text-transform: uppercase;
      box-shadow: 0 3px 6px 0 rgba(105, 224, 216, 0.16);
      background-color: #1bbcf1;
      cursor: pointer;

      @media screen and (max-width: $mobile-view) {
        position: fixed;
        bottom: 75px;
        width: 130px;
        height: 35px;
        font-size: 19px;
        text-align: center;
        padding: 0px;
        padding-top: 10px;
      }
    }
  }
}

.events-info-table {
  font-size: 18px;
  border: 0px;

  &__key, &__value {
    box-sizing: border-box;
    padding-bottom: 15px;
  }

  &__key {
    width: 115px;
    display: block;
    color: $color-style;
    text-align: right;
    font-weight: 800;
  }

  &__value {
    padding-left: 10px;
    line-height: 1.4;

    &__call {
      margin-top: 5px;
    }
  }
}
