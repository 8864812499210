@import "../../scss/imports";

.event {
  width: 80vw;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 100px auto 0 auto;

  @media screen and (max-width: $mobile-view) {
    flex-flow: column;
    align-items: center;
    margin: 0px auto;

    position: fixed;
    bottom: 125px;
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
  }
}
